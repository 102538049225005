import Media from "components/Media";
import useComponentDimensions from "hooks/use-component-dimensions";
import { throttle } from "lodash";
import React, {
  Children,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from "react";

import styles from "./styles.module.scss";

interface Props {
  children: ReactNode[];
  itemWidth: number | string;
  className?: string;
  colors?: { indicatorColor?: string };
  onViewChange?(newView: number): void;
}

const ScrollView: FC<Props> = ({
  children,
  itemWidth,
  className,
  colors = {},
  onViewChange
}) => {
  const [scroll, setScroll] = useState(0);
  const [selected, setSelected] = useState(0);

  const { measure, width } = useComponentDimensions({ cache: { width: 10 } });

  const onScroll = useCallback(
    throttle((event) => {
      if (event?.currentTarget) setScroll(event.currentTarget.scrollLeft);
    }, 50),
    []
  );

  const elements = children.length;

  useEffect(() => {
    setSelected((prevState) => {
      const newState = Math.round(scroll / width);

      if (newState !== prevState && newState < children.length && newState >= 0)
        onViewChange?.(newState);

      return newState;
    });
  }, [scroll]);

  return (
    <>
      <div
        ref={measure}
        onScroll={onScroll}
        className={`${styles.container} ${className}`}
      >
        {Children.map(children, (child) => (
          <div
            key={child?.toLocaleString()}
            style={{ minWidth: itemWidth, width: itemWidth }}
            className={styles.child}
          >
            {child}
          </div>
        ))}
      </div>
      <Media at={["responsive"]}>
        <div className={styles.indicators}>
          {new Array(elements).fill(0).map((_, index) => (
            <div
              style={{ backgroundColor: colors.indicatorColor }}
              className={`${styles.indicator} ${
                index === selected && styles.selectedIndicator
              }`}
            />
          ))}
        </div>
      </Media>
    </>
  );
};

ScrollView.defaultProps = {
  className: undefined,
  colors: {},
  onViewChange: undefined
};

export default ScrollView;
