/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

const useAsync = (
  asyncRequest: (...params: any[]) => Promise<void>,
  failureCallback?: (error: string) => void
): [(...params: any[]) => Promise<boolean>, boolean] => {
  const [inProgress, setInProgress] = useState(false);

  const fetch = (...params: any[]) => {
    setInProgress(true);
    return asyncRequest(...params)
      .then(() => {
        return true;
      })
      .catch((newError: string) => {
        failureCallback?.(newError);
        return false;
      })
      .finally(() => {
        setInProgress(false);
        return false;
      });
  };

  return [fetch, inProgress];
};

export default useAsync;
