import React, { FC } from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";

import Marquee from "react-fast-marquee";

type Props = {
  children: ReactElement[];
  className?: string;
  gradient?: boolean;
};

const Carousel: FC<Props> = ({ children, className, gradient = true }) => {
  return (
    <Marquee
      gradient={gradient}
      gradientWidth={50}
      pauseOnHover
      pauseOnClick
      speed={40}
      className={className}
    >
      {children}
    </Marquee>
  );
};

Carousel.defaultProps = {
  className: undefined,
  gradient: true
};

export default Carousel;
