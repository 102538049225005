import React, { FC } from "react";
import { AiFillStar as StarIcon } from "react-icons/ai";
import styles from "./styles.module.scss";
import { STAR_SIZE } from "./constants";

type Props = {
  stars: number;
  className?: string;
  starSize?: number;
};

const StarRating: FC<Props> = ({ stars, className, starSize = STAR_SIZE }) => (
  <div className={`${styles.container} ${className}`}>
    <div style={{ width: 5 * starSize }} className={styles.fullSizeWrapper}>
      <div style={{ width: stars * starSize }} className={styles.background}>
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.backgroundStar}
        />
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.backgroundStar}
        />
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.backgroundStar}
        />
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.backgroundStar}
        />
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.backgroundStar}
        />
      </div>
      <div style={{ width: stars * starSize }} className={styles.wrapper}>
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.star}
        />
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.star}
        />
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.star}
        />
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.star}
        />
        <StarIcon
          style={{ minWidth: starSize, height: starSize }}
          className={styles.star}
        />
      </div>
    </div>
  </div>
);

StarRating.defaultProps = {
  starSize: STAR_SIZE,
  className: undefined
};

export default StarRating;
